import React from "react";
import Layout from "../../components/Layout";
import StudentCategoryList from "../../components/StudentCategoryList";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Math Games Category | Student Resources";
  const description =
    "Learn and practice math concepts, facts, and strategies through fun, focused activities.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Math Games</h1>
            <p>{description}</p>
            <StudentCategoryList category="math-games" />
          </div>
        </div>
      </section>
    </Layout>
  );
};
